var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"container-rda",attrs:{"id":"timeline-page"}},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Linha do tempo")])])],1),(this.student !== null)?_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-avatar',{staticClass:"mb-4",attrs:{"src":this.student.User && this.student.User.photo
            ? this.student.User.photo
            : '../../assets/images/user_placeholder.png',"size":"17rem"}}),_c('div',{staticClass:"info-student"},[_c('h4',[_vm._v(_vm._s(_vm.student.User.name))]),_c('ul',[_c('li',[_c('b',[_vm._v("Turma")]),_c('p',[_vm._v(" "+_vm._s(_vm.classroom && _vm.classroom.Cycle ? _vm.classroom.Cycle.designation : "")+" - "+_vm._s(_vm.classroom && _vm.classroom.year_id ? _vm.classroom.year_id : "")+"° ano ")])])])])],1),_c('b-col',[_c('b-row',{staticClass:"filters"},[_c('b-col',{attrs:{"md":"4","lg":"4"}},[_c('SelectSimple',{attrs:{"label":"Categoria","options":_vm.allCategories,"callback":_vm.selectCategoryFilter},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}})],1),_c('b-col',{attrs:{"md":"4","lg":"4"}},[_c('SelectSimple',{attrs:{"label":"Mês","options":_vm.allMonth,"callback":_vm.selectMonthFilter},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1),_c('b-col',{staticClass:"wrap-button-new",attrs:{"md":"4","lg":"4"}},[_c('b-button',{staticClass:"btn blue mb-5",on:{"click":_vm.openModalNewRegister}},[_c('b-icon-plus'),_vm._v(" Novo registro ")],1)],1)],1),_vm._l((_vm.items),function(item,index){return _c('b-row',{key:index},[_c('TimelineItem',{attrs:{"resource":item},on:{"onRemove":function($event){return _vm.removeItemList(index)},"onEdit":function($event){return _vm.openModalEditRegister(item)}}})],1)})],2)],1):_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('Loading')],1)],1),_c('TimelineRegister',{attrs:{"title":_vm.modalTitle}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitRegister.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Categoria"}},[_c('SelectSimple',{attrs:{"options":_vm.modalTitle === 'Editar registro'
                  ? _vm.categories
                  : _vm.allCategories.filter(function (category) { return category.id !== '-1'; }),"callback":_vm.selectGeneralType},model:{value:(_vm.registerData.general_type),callback:function ($$v) {_vm.$set(_vm.registerData, "general_type", $$v)},expression:"registerData.general_type"}})],1)],1)],1),(
          _vm.registerData.general_type &&
          _vm.registerData.general_type.id == 'followup'
        )?_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Campo"}},[_c('SelectSimple',{attrs:{"options":_vm.allFields,"callback":_vm.selectCategoryField,"required":""},model:{value:(_vm.registerData.category),callback:function ($$v) {_vm.$set(_vm.registerData, "category", $$v)},expression:"registerData.category"}})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"description":""}},[_c('label',{attrs:{"for":"label"}},[_vm._v("Texto")]),_c('b-form-textarea',{attrs:{"type":"textarea","placeholder":"","required":""},model:{value:(_vm.registerData.content),callback:function ($$v) {_vm.$set(_vm.registerData, "content", $$v)},expression:"registerData.content"}})],1)],1),_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('div',{staticClass:"wrapper-switch",on:{"click":_vm.switchVisibleForParents}},[_c('b-form-group',{staticClass:"switch_parents"},[_c('span',{staticClass:"label"},[_vm._v("Visível para os responsáveis")]),_c('b-form-checkbox',{attrs:{"size":"lg","switch":"","required":""},on:{"change":_vm.switchVisibleForParents},model:{value:(_vm.registerData.visible_for_parents),callback:function ($$v) {_vm.$set(_vm.registerData, "visible_for_parents", $$v)},expression:"registerData.visible_for_parents"}})],1)],1)])],1):_vm._e(),(
          _vm.registerData.general_type &&
          _vm.registerData.general_type.id != 'followup'
        )?_c('b-row',[(
            _vm.registerData.general_type &&
            _vm.registerData.general_type.id !== 'followup'
          )?_c('b-col',{attrs:{"md":"12","lg":"12"}},[_c('UploadFiles',{attrs:{"noUploadPreview":false},model:{value:(_vm.registerData.file),callback:function ($$v) {_vm.$set(_vm.registerData, "file", $$v)},expression:"registerData.file"}})],1):_vm._e()],1):_vm._e(),_c('b-row',{staticClass:"footer-modal",attrs:{"md":"12","lg":"12"}},[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn blue",attrs:{"type":"submit","disabled":!_vm.allFieldsFilled}},[(_vm.submitLoading)?_c('span',[_vm._v(_vm._s(_vm.modalTitle === "Editar registro" ? "Atualizando..." : "Registrando..."))]):_c('span',[_vm._v(_vm._s(_vm.modalTitle === "Editar registro" ? "Atualizar " : "Criar ")+" Registro")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }